.menu-toggle {
    height: 16px;
    width: 22px;
    @extend .u-cursor-pointer;
    position: relative;
    @include rotate(0);
    @include transition(.5s ease-in-out);
    display: block;

    @include mq(tablet-max) {
        display: none;
    }

    i,
    i:after,
    i:before {
        display: block;
        position: absolute;
        height: 3px;
        width: 22px;
        right: 0;
        background-color: var(--greyishBlue);
        @include border-radius(2px);
        @include rotate(0);
        @include transition(.25s ease-in-out);
    }

    i{
        top: 16px;
        &:after,
        &:before {
            content: '';
        }

        &:after {
            top: -16px;
        }
        &:before {
            top: -8px;
        }
    }

    &.open {
        i {
            &:after {
                @include rotate(45);
                top: -14px;
            }

            &:before {
                @include rotate(-45);
                top: -14px;
            }
        }

        i,
        i:after,
        i:before {
            background-color: var(--lightBlue);
        }
    }
}