@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  -ms-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin box-shadow2(
  $shadow1,
  $shadow2: false,
  $shadow3: false,
  $shadow4: false,
  $shadow5: false
) {
  $params: $shadow1;

  @if $shadow2 {
    $params: $shadow1, $shadow2;
  }

  @if $shadow3 !=false {
    $params: $shadow1, $shadow2, $shadow3;
  }

  @if $shadow4 !=false {
    $params: $shadow1, $shadow2, $shadow3, $shadow4;
  }

  @if $shadow5 !=false {
    $params: $shadow1, $shadow2, $shadow3, $shadow4, $shadow5;
  }

  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

/* Declaration */
/* @include box-shadow2(-1px -1px 2px rgba(0, 0, 0, .05), 0 1px 1px white inset) */

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

/* Transform */
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  /* Chrome, Safari, Opera */
  -moz-transform: $transforms;
  /* Mozilla Firefox */
  -o-transform: $transforms;
  -ms-transform: $transforms;
  /* IE 9 */
  transform: $transforms;
}

/* rotate */
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

/* scale */
@mixin scale($scale) {
  @include transform(scale($scale));
}

/* translate */
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin translateY($y) {
  @include transform(translateY($y));
}

@mixin translateX($x) {
  @include transform(translateX($x));
}

/* skew */
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

/* transform origin */
@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  /* Chrome, Safari, Opera */
  -moz-transform-origin: $origin;
  /* Mozilla Firefox */
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  /* IE 9 */
  transform-origin: $origin;
}

/* Image Cover Size */
@mixin background-size($background-size) {
  -webkit-background-size: $background-size !important;
  /* Chrome, Safari, Opera */
  -moz-background-size: $background-size !important;
  /* Mozilla Firefox */
  -ms-background-size: $background-size !important;
  /* IE 9 */
  -o-background-size: $background-size !important;
  background-size: $background-size !important;
}

@mixin blur($radius) {
  filter: blur($radius + px);
  filter: progid:DXImageTransform.Microsoft.Blur(Strength=#{$radius});
  -webkit-filter: blur($radius + px);
  -ms-filter: blur($radius + px);
  -o-filter: blur($radius + px);
  -moz-filter: blur($radius + px);
}

/* Sizes */
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Image Object Fit */
@mixin object-fit($object-fit) {
  -webkit-object-fit: $object-fit;
  /* Chrome, Safari, Opera */
  -moz-object-fit: $object-fit;
  /* Mozilla Firefox */
  -ms-object-fit: $object-fit;
  /* IE 9 */
  -o-object-fit: $object-fit;
  object-fit: $object-fit;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

/* 
 @include keyframes(background) {
   0% {
     background: white;
   }
   50% {
     background: lightblue;
   }
   100% {
     background: royalblue;
   } 
}
*/

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin flexboxImp() {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}

@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin flex-direction($direction, $orient) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
  -webkit-box-orient: $orient;
  -moz-box-orient: $orient;
  -ms-box-orient: $orient;
  box-orient: $orient;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-column {
  @include flexbox();
  @include flex-direction(column, vertical);
}

@mixin flex-center {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
}

@mixin flex-center-column {
  @include flex-center;
  @include flex-direction(column, vertical);
}

@mixin flex-center-vert {
  @include flexbox();
  @include align-items(center);
}

@mixin flex-center-horiz {
  @include flexbox();
  @include justify-content(center);
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin font-size($sizeValue: 16) {
  font-size: ($sizeValue / 16) + rem;
}

$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-max": 992px,
  "tablet-wide": 1024px,
  "desktop-min": 1200px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
  "desktop-wide-max": 1920px,
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

/*

    .site-header {
    padding: 2rem;
    font-size: 1.8rem;
    @include mq('tablet-wide') {
        padding-top: 4rem;
        font-size: 2.4rem;
    }
}*/

@mixin overlay() {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);

    @if $i and $i + 1 <=length($args) and type-of(nth($args, $i + 1)) ==number {
      #{$o}: nth($args, $i + 1);
    }
  }

  position: $position;
}

@mixin retina($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    /* Serving 2x image on Retina display */
    background-image: url($image);
    background-size: $width $height;
  }
}

/* .logo {
background-image: url("img/logo.png");
  @include retina("img/logo@2x.png", 100px, 21px);
}*/

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + ".eot");
    src: url($file-name + ".eot?#iefix") format("embedded-opentype"),
      url($file-name + ".woff2") format("woff2"),
      url($file-name + ".woff") format("woff"),
      url($file-name + ".ttf") format("truetype"),
      url($file-name + ".svg##{$font-name}") format("svg");
    font-weight: $weight;
    font-style: $style;
  }
}

/* usage @include font-face("MyFont", "path/to/MyFont", $style: normal, $weight: normal); */

@mixin css3-prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin primaryFont($weight) {
  font-family: var(--fontFamily);
  font-weight: $weight;
}