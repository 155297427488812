.elem8 {
    $selfElem8: &;
    padding-top: 80px;
    padding-bottom: 50px;

    &__head {
        @include font-size(30);
        @include primaryFont(400);
        color: var(--blue5);
        text-align: center;
        padding-bottom: 11px;
        @include mq(tablet) {
            text-align: left;
            @include font-size(36);
        }
    }

    .item {
        background-color: var(--grey);
    }
    &__slidertext {
        @include flexbox;
        @include flex-direction(column, vertical);
        & .button {
            display: none;
            @include mq(tablet) {
                display: block;
            }
        }
        &-img {
            @include size(100%);
            margin: 0px;
            padding: 0px;
            background-position: center;
            background-size: cover;

            & .u-img {
                width: 100%;
                height: auto;
                @include mq(tablet) {
                    display: none !important;
                }
            }

            &:before {
                content: "";
                padding-top: 56.25%;
                width: 100%;
                display: block;
            }
            @include mq(tablet-wide) {
                width: 50%;
                min-height: 100%;

                &:before {
                    padding-top: 90%;
                }
            }

            @include mq(desktop-wide) {
                &:before {
                    padding-top: 56.25%;
                }
            }
        }
        &-text {
            width: 100%;
            background: var(--grey);
            padding: 35px 32px 45px 12px;

            & .titlehead {
                @include font-size(20);
                @include primaryFont(700);
                color: var(--blue5);
                line-height: 1.3;
                padding-bottom: 20px;
                @include mq(tablet) {
                    @include font-size(24);
                    padding-bottom: 15px;
                }
                @include mq(desktop-wide) {
                    @include font-size(28);
                }
                @include mq(desktop-wide-max) {
                    @include font-size(30);
                }
            }
            & .detail {
                @include font-size(16);
                @include primaryFont(300);
                color: var(--blue5);
                line-height: 1.8rem;
                margin-bottom: 20px;

                @include mq(desktop-wide) {
                    @include font-size(18);
                }
                @include mq(desktop-wide-max) {
                    @include font-size(20);
                }
            }
            @include mq(tablet-wide) {
                width: 50%;
                @include flexbox;
                @include flex-direction(column, vertical);
                @include justify-content(center);
                padding: 23px 72px;
            }

            @include mq(tablet-max) {
                width: 50%;
                padding: 40px 15px 40px 40px;
            }

            @include mq(desktop-min) {
                @include calc(width, "1140px / 2");
            }
            @include mq(desktop-wide-min) {
                @include calc(width, "1320px / 2");
            }

            @include mq(desktop-wide-max) {
                @include calc(width, "1320px / 2 + 200px");
            }
        }
        @include mq(tablet-wide) {
            @include flex-direction(row, horizontal);
            @include align-items(inherit);
        }
    }
    &.d2 {
        padding: 0;
        margin-bottom: 20px;
        @include mq(tablet) {
            margin-bottom: 90px;
        }

        #{$selfElem8} {
            &__slidertext {
                position: relative;
                margin-bottom: 50px;
                min-height: auto;
                @include mq(tablet-max) {
                    margin-bottom: 165px;
                    min-height: 590px;
                }
                @include mq(tablet) {
                    margin-bottom: 165px;
                    min-height: 590px;
                }

                &-img {
                    width: 100%;
                }
                &-text {
                    padding: 45px;
                    background: rgba(255, 255, 255, 0.9);
                    width: 100%;
                    max-width: 700px;
                    @include box-shadow(0px 15px 30px rgba(0, 0, 0, 0.2));
                    .detail {
                        font-size: 1.2rem;
                    }
                }
                .button {
                    display: block;
                }
            }
            &__container {
                position: static;
                bottom: 0px;
                max-width: 1728px;
                margin: 0 auto;
                width: 100%;
                padding: 0px;
                @include mq(tablet-max) {
                    position: absolute;
                    bottom: -115px;
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                }
                @include mq(tablet) {
                    position: absolute;
                    bottom: -115px;
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                }
            }
        }
        .owl-dots {
            @include mq(tablet-max) {
                bottom: 160px;
                left: 55%;
            }
        }
        .owl-nav {
            @include mq(tablet-max) {
                bottom: 157px;
                left: 55%;
            }
        }
    }
}
