.elem7 {
    $selfElem7: &;
    width: 100%;
    position: relative;
    min-height: 200px;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    background-size: cover !important;
    overflow: hidden;
    & .custom_container {
        position:relative;
        z-index: 2;
    }
    &:after {
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        left:0px;
        top:0px;
        z-index: 1;
        background: linear-gradient(180deg, rgba(16, 22, 83, 0) 7.81%, rgba(14, 20, 79, 0.8) 58.33%, rgba(3, 1, 57, 0.8) 100%);
       
       
    }
    &__contentwrap {
        padding-top:60px;
        padding-bottom: 60px;
        &-head {
            @include font-size(28);
            @include primaryFont(700); 
            line-height: 32px;
            color:var(--white);
            margin-bottom: 22px;
            @include mq(tablet-max) {
                @include font-size(34);
                line-height: 38px;
            }
        }
        &-text {
            @include font-size(19);
            @include primaryFont(300);  
            line-height: 26px;
            color:var(--white);
            margin-bottom: 22px;

            @include mq(tablet-max) {
                @include font-size(22);
                line-height: 30px;
            }
        }
        &-subhead {
            @include font-size(23);
            @include primaryFont(400);  
            line-height: 28px;
            color:var(--white);
            margin-bottom: 25px;
            @include mq(tablet-max) {
                @include font-size(26);
                line-height: 30px;
            }
        }
        &-linkwarp {
           width: 100%;
           height: 58px;
           @include flexbox;
           @include justify-content(center);
           @include align-items(center);
           @include border-radius(12px);
           @include font-size(18);
           @include primaryFont(300); 
           color:var(--lightBlue);
           background: var(--white);
           margin-bottom: 16px;

           @include mq(tablet-max) {
                @include font-size(18);
                margin-bottom: 20px;
            }
        }
    }
}