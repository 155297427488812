.section_header {
    $selfSecHeader: &;
    margin: 50px 0;

    &_title {
        @include font-size(32);
        @include primaryFont(300);
        color: var(--orange);
        margin-bottom: 15px;

        @include mq(desktop-wide) {
            @include font-size(36);
        }
        @include mq(desktop-wide-max) {
            @include font-size(48);
        }
        &.pf--bold {
            @include primaryFont(700);  
        }
    }

    &_desc {
        @include font-size(18);
        @include primaryFont(300);
        color: var(--greyishBlue);
        line-height: 1.75;
        

        @include mq(desktop) {
            @include font-size(24);
            max-width: 80%;
        }
    }
    &_subdesc {
        @include font-size(16); 
        @include primaryFont(400);
        color: var(--white);
        line-height: 1.75; 

        @include mq(desktop) {
            @include font-size(18);
            max-width: 80%;
            margin-top: 40px; 
        }
    }

    &--centered {
        @include flex-center-column;
        text-align: center;
    }


    &--2 {
        margin: 0px;
        #{$selfSecHeader} {
            &_title {
                @include font-size(36);
                @include primaryFont(500);
                color: var(--grey3);
                margin-bottom: 15px;

                @include mq(desktop-wide) {
                    @include font-size(48);
                }
                @include mq(desktop-wide-max) {
                    @include font-size(60);
                }
            }

            &_desc {
                margin-bottom: 30px;
        
                @include mq(desktop) {
                    max-width: 70%;
                }
            }
        }
    }

    &--3 {
        margin: 0px 0 30px;
        #{$selfSecHeader} {
            &_title {
                @include font-size(32);
                color: var(--grey3);

                @include mq(desktop-wide) {
                    @include font-size(38);
                }
                @include mq(desktop-wide-max) {
                    @include font-size(42);
                }
            }
        }
    }

    &--4 {
        margin: 0px;
        #{$selfSecHeader} {
            &_title {
                @include font-size(36);
                @include primaryFont(300);
                color: var(--grey3);
                margin-bottom: 15px;

                @include mq(desktop-wide) {
                    @include font-size(48);
                }
                @include mq(desktop-wide-max) {
                    @include font-size(60);
                }
            }

            &_desc {
                @include font-size(22);
                @include primaryFont(400);
                line-height: 1.3;
                color: var(--grey3);
                margin-bottom: 15px;
                max-width: 100%;

                @include mq(desktop-wide) {
                    @include font-size(26);
                }
                @include mq(desktop-wide-max) {
                    @include font-size(30);
                }
            }

            &_sub_desc {
                @include font-size(14);
                @include primaryFont(300);
                line-height: 1.8;
                color: var(--grey3);
                margin-bottom: 15px;

                @include mq(desktop-wide) {
                    @include font-size(16);
                }
                @include mq(desktop-wide-max) {
                    @include font-size(20);
                }
            }
        }
    }
    &--5{
        margin:0px;

        #{$selfSecHeader} {
            &_title {
                position: relative;
                @include font-size(32);
                @include primaryFont(300);
                color: var(--blue2);
                padding-bottom:23px;
                margin-bottom: 23px;
                &:after {
                    content:'';
                    position: absolute;
                    bottom:0px;
                    left:0px;
                    width:150px;
                    height: 5px;
                    background-color: var(--orange);
                    @include mq(desktop-min) {
                        width:230px;
                    }

                }

                @include mq(desktop-min) {
                    @include font-size(42);
                    padding-bottom: 35px;
                    margin-bottom: 35px;
                }
               
            }
            &_desc {
                @include font-size(25);
                @include primaryFont(300);
                line-height: 35px;
                color: var(--blue5);
                margin-bottom:0px;
                max-width: 100%;

                @include mq(desktop-min) {
                    @include font-size(28);
                    line-height: 38px;
                }
            }
        }
    }

    &--6{
        margin: 80px 0px;

        &_desc {
            @include mq(desktop) {
                @include font-size(24);
                max-width: 60%;
            }
        }
    }

    &--7 {
        margin: 0px;
        #{$selfSecHeader} {
            &_title {
                @include font-size(18);
                @include primaryFont(700);
                color: var(--orange);
                margin-bottom: 5px;

                @include mq(desktop-wide) {
                    @include font-size(20);
                }
            }

            &_desc {
                @include font-size(16);
                color: var(--blue5);
                @include primaryFont(400);
                margin-bottom: 5px;

                a {
                    color: var(--blue5);

                    &:hover {
                        color: var(--orange);
                    }
                }

                .text_bold {
                    @include primaryFont(700);
                }
            }
        }
    }

    &--8 {
        margin: 0px;
        #{$selfSecHeader} {
            &_title {
                @include font-size(28);
                @include primaryFont(700);
                color: var(--orange);
                margin-bottom: 15px;
                text-transform: uppercase;

                @include mq(desktop-wide) {
                    @include font-size(30);
                }
                @include mq(desktop-wide-max) {
                    @include font-size(32);
                }
            }
        }
    }
}