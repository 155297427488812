.button {
    background-color: transparent;
    @extend .u-cursor-pointer;
    border: none;
    line-height: normal;
    @include transition(all 0.5s ease);
    @include primaryFont(400);
    @include font-size(16);
    padding: 15px 30px;
    text-decoration: none;
    @include inline-flex;
    @include justify-content(center);
    @include border-radius(8px);

    &.w100 {
        width: 100%;
    }

    &--radius50 {
        @include border-radius(50px);
    }

    &--radius12 {
        @include border-radius(12px);
    }
    &--bordered {
        border: 1px solid var(--white);
        color: var(--white);
        @include flexbox();
        @include justify-content(center);

        &:hover {
            color: var(--black);
            background-color: var(--white);
        }
    }
    &--white {
        background-color: var(--white);
        color: var(--lightBlue);

        &-bordered {
            background-color: transparent;
            color: var(--lightBlue);
            border: 1px solid var(--lightBlue);

            &:hover {
                background-color: var(--lightBlue);
                color: var(--white);
            }
        }
        &-bluebordered {
            background-color: var(--white);
            color: var(--lightBlue);
            border: 1px solid var(--lightBlue);
            text-align: center;

            &:hover {
                background-color: var(--lightBlue);
                color: var(--white);
            }
        }
    }
    &--purple {
        background-color: var(--lightBlue);
        color: var(--white);
        border: 1px solid var(--lightBlue);

        &:hover {
            color: var(--lightBlue);
            background-color: var(--white);
        }
    }

    &--size-l {
        @include font-size(22);
    }
    &--w180 {
        width: 180px;
    }
    &--w200 {
        width: 200px;
    }
    &--text-center {
        text-align: center;
    }
    &:disabled {
        cursor: not-allowed;
        @include opacity(0.4);
        color: var(--black);
    }
}

.button_group {
    @include flexbox;
    @include flex-direction(column, vertical);

    @include mq(tablet) {
        @include flex-direction(row, horizontal);
    }

    .button {
        + .button {
            margin-top: 15px;

            @include mq(tablet) {
                margin-top: 0;
                margin-left: 15px;
            }
        }
    }
}
.blogtheme{
    background:#f3f3f3;
}
