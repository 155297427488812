.elem6 {
    $selfElem6: &;
    padding-top: 40px;
    padding-bottom: 50px;
    &__imgwrap {
        width: 100%;
        & .u-img {
            width: 100%;
            height: auto;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            -ms-box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
            -o-box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
                rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        }
    }
    &__contentwrap {
        padding-bottom: 10px;
        height: 100%;

        &-listitem {
            width: 100%;
            @include flexbox;
            @include flex-direction(column, vertical);
            padding-top: 30px;
            position: sticky;
            top: 60px;
            
            &--item {
                width: 100%;
                @include flexbox;
                @include align-items( stretch);
                margin-bottom: 21px;
                border: 1px solid var(--blue5);
                @include border-radius(12px);
                padding: 20px 25px;
                overflow: hidden;
                & .icon {
                    width: 100px;
                    margin-right: 20px;
                    text-align: center;
                    & .u-img {
                        height: 65px;
                        width: auto;
                        @include mq(tablet) {
                            height: 50px;
                        }
                        @include mq(tablet-wide) {
                            height: 65px;
                        }
                    }
                    @include mq(tablet) {
                        width: 80px;
                    }
                    @include mq(tablet-wide) {
                        width: 100px;
                        margin-right: 30px;
                    }
                }
                & .content {
                    width: calc(100% - 120px);
                    & .title {
                        @include font-size(19);
                        @include primaryFont(400);
                        color: var(--blue5);
                        margin-bottom: 10px;
                        @include mq(desktop-wide) {
                            @include font-size(24);
                            margin-bottom: 14px;
                        }
                    }
                    & .detail {
                        @include font-size(16);
                        @include primaryFont(300);
                        line-height: 24px;
                        color: var(--blue5);
                        margin-bottom: 0px;
                        @include mq(desktop-wide) {
                            @include font-size(18);
                            line-height: 30px;
                        }
                    }
                    @include mq(tablet) {
                        width: calc(100% - 100px);
                    }
                }
                @include mq(tablet) {
                    width: calc(100% / 2 - 30px);
                    margin-left: 15px;
                    margin-right: 15px;
                    padding: 15px;
                }
                @include mq(tablet-max) {
                    width: 100%;
                    margin-left: 0px;
                    margin-right: 0px;
                }
                @include mq(tablet-wide) {
                    padding: 30px 40px;
                }
            }
            @include mq(tablet) {
                @include flex-direction(row, horizontal);
                @include justify-content(center);
                @include align-items( stretch);
                @include flex-wrap(wrap);
                width: calc(100% + 30px);
                margin-left: -15px;
                margin-right: -15px;
            }
            @include mq(tablet-max) {
                @include flex-direction(column, vertical);
                width: 100%;
                margin-left: 0px;
                margin-right: 0px;
            }
            @include mq(desktop-min) {
                padding-top: 60px;
            }
        }
        @include mq(tablet-max) {
            padding: 90px 50px 50px 0px;
        }
        @include mq(desktop-min) {
            padding: 90px 50px 50px 0px;
        }

        @include mq(desktop-wide) {
            padding: 130px 60px 60px 0px;
        }
    }
}
