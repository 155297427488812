.elem13 {
    $selfElem13: &; 
    margin-top: 60px;
     
    &__container {
        &-head {
            @include flexbox();
            @include justify-content(center);
            margin-bottom: 65px;
            .nav {
                @include flex-wrap(nowrap);  
                overflow-x: scroll;
                padding-bottom: 15px;
                &::-webkit-scrollbar {
                    display: none; 
                }
                &::-webkit-scrollbar {
                    width: .2em;
                    background: transparent;
                }
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
                  }
                  &::-webkit-scrollbar-thumb {
                    background-color: transparent;
                    outline: none; 
                  }
                .nav-item {
                    margin-left: 5px;
                    margin-right: 5px;
                    .nav-link {
                        min-width: 150px;
                        color: var(--lightBlue);
                        border: 1px solid var(--lightBlue);
                        @include border-radius(40px); 
                        @include font-size(16);
                        @include primaryFont(400);
                        &.active {
                            background: var(--lightBlue);
                            color: var(--white); 
                        }
                    }
                }
            }
        }
    }
}