.body-content {
	.ui-container {
		display: flex;
		background-size: cover;
		background-position: center top;
		.row-wide {
			width: 100%;
		}
		.row {
			width: 100%;
			margin: 0 auto;
			padding-top: 1.25em;
			padding-bottom: 1.25em;
		}
	}
}
.content-module {
	margin-bottom: 1.25rem;
	line-height: 1.4rem;
}
.section-container {
	width: 100%;
	position: relative;
}
.section-container-2 {
	height: 100vh;
	background: #fff;
	overflow: hidden;
	.panel-1 {
		background-size: cover;
		.img-1 {
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-size: cover;
		}
		.title-1 {
			bottom: 23%;
			margin-bottom: 2rem;
		}
		.title-2 {
			bottom: 15%;
            @include primaryFont(300);   
			font-size: 1.25rem;
			line-height: 1.5vw;
			bottom: 8%;
			font-size: 42px;
			line-height: 44px;
			text-align: center;
			color: rgb(80, 87, 114);
		}
	}
	.panel-2 {
		.img-3 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-size: cover;
		}
	}
}
.panel {
	margin: 0px;
	padding: 0px;
	width: 100vw;
	height: 100vh;
	position: absolute;
	overflow: hidden;
	background: transparent;
	border: none !important;
	box-shadow: none !important;
}
.panel-1 {
	z-index: 4;
}
svg {
	&:not(:root) {
		overflow: hidden;
	}
}
.height-inherit {
	height: inherit;
}
.join-us {
	position: relative;
	bottom: -79%;
	.animate_title {
		opacity: 0;
		position: relative;
		text-align: center;
		left: 50%;
		-webkit-transform: translate(-50%, 50%);
		-ms-transform: translate(-50%, 50%);
		transform: translate(-50%, 50%);
		font-size: 3.29375rem;
		line-height: 3.3rem;
		display: block;
		line-height: 1;
		margin-bottom: 1rem;
	}
	.animate_title.isactive {
		opacity: 1;
	}
}
#block-section-3b {
	.panel-1 {
		.animate_title {
			span {
				&:nth-child(1) {
					-webkit-transition: opacity 0.6s ease-in, top 0.8s ease;
					-o-transition: opacity 0.6s ease-in, top 0.8s ease;
					transition: opacity 0.6s ease-in, top 0.8s ease;
				}
			}
		}
	}
	.section-container-2 {
		.panel-2 {
			.animate_title {
				opacity: 1;
				position: relative;
				text-align: left;
				left: 0;
				color: var(--white);
				display: flex;
				height: 100%;
				align-items: center;
			}
		}
	}
}
.panel-2.isactive {
	z-index: 5;
	.img3 {
		height: 100%;
	}
}
.animate_title.careers {
	@include primaryFont(300);   
	line-height: 1.3 !important;
}
.section-container-9 {
	.panel-1 {
		.animate_title {
			opacity: 1;
			position: relative;
			text-align: left;
			left: 0;
			color: var(--white);
			display: flex;
			height: 100%;
			align-items: center;
		}
	}
}

.panel.panel-1 {
	background-color: var(--white);
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
	.animate_title.careers {
		font-size: 22px !important;
	}
}
@media screen and (max-width: 1280px) {
	#block-section-3b {
		.section-container-2 {
			.panel-2 {
				.animate_title {
					opacity: 1;
					position: relative;
					text-align: left;
					left: 0;
					color: var(--white);
					-webkit-transform: none !important;
					-ms-transform: none !important;
					transform: none !important;
					bottom: 0;
				}
			}
		}
	}
	.section-container-9 {
		.panel-1 {
			.animate_title {
				opacity: 1;
				position: relative;
				text-align: left;
				left: 0;
				color: var(--white);
				-webkit-transform: none !important;
				-ms-transform: none !important;
				transform: none !important;
				bottom: 0;
			}
		}
	}
	.section-container-2 {
		.panel-2 {
			.text-container {
				position: absolute;
				top: 0;
				left: 0;
				top: 50%;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1300px) {
	.section-container-2 {
		.panel-2 {
			.text-container {
				top: 35%;
				top: 35%;
			}
		}
	}
}

@media screen and (min-width: 1000px) {
	.body-content {
		.ui-container {
			.row {
				max-width: 78.125rem;
			}
		}
	}
	.new-row {
		max-width: 61.25em !important;
	}
}
